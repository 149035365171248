<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction, putAction } from '@/command/netTool'
export default {
  name: 'specialtyGoods',
  data() {
    return {
      ...api.command.getState(),
      spinning: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const { syncTenantId } = this.$route.query
      api.command.getList.call(this, {
        url: '/farmSyncNew/page',
        current: 1,
        paramsValue: {
          syncTenantId: syncTenantId,
        },
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'nativeProductName',
          type: 'input',
        },
        {
          name: '主体名称',
          key: 'linkShop',
          type: 'input',
        },
      ]
    },
    getColumns() {
      const { syncTenantId } = this.$route.query
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'nativeProductName',
          title: '特产名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.bannerUrl ? <img src={records.bannerUrl.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.nativeProductName.length - b.nativeProductName.length,
        },
        {
          dataIndex: 'farmNativeProductStandardsVOS',
          title: '规格',
          getData: (records) => records.farmNativeProductStandardsVOS.slice(0, 3).map((e) => e.standardsName),
          type: 'tagGroup',
          align: 'left',
          onExport: (text, records) => {
            let arr = text.map((e) => {
              return e.standardsName
            })
            return arr.toString()
          },
        },
        {
          dataIndex: 'linkShop',
          title: '关联主体',
          align: 'left',
        },
        {
          dataIndex: 'salePrice',
          title: '现价',
          align: 'left',
          customRender: function (text, records) {
            return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
          },
        },
        {
          dataIndex: 'vipPrice',
          title: '会员价',
          align: 'left',
          customRender: function (text, records) {
            return records.vipPrice == null ? '' : <div>{'￥' + records.vipPrice}</div>
          },
        },
        {
          dataIndex: 'categories',
          title: '分类',
          align: 'left',
        },
        {
          dataIndex: 'syncStatus',
          title: '状态',
          type: 'badge',
          render(data, records) {
            let statusIndex = 5
            if (records.syncStatus == '0') {
              if (records.cyStatus == '1') {
                statusIndex = 2
              } else {
                statusIndex = 1
              }
            } else {
              if (records.tbStatus == '1') {
                if (records.cyStatus == '1') {
                  statusIndex = 2
                } else {
                  statusIndex = 0
                }
              } else if (records.tbStatus == '2') {
                statusIndex = 3
              } else if (records.tbStatus == '0') {
                statusIndex = 4
              }
            }
            return {
              showDot: true,
              name: {
                0: '已同步',
                1: '未同步',
                2: '有差异',
                3: '同步失败',
                4: '同步中',
                5: '',
              }[statusIndex],
              color: {
                0: '#18be6b',
                1: '#e6ebf1',
                2: '#f59a23',
                3: '#ed4014',
                4: '#2d8cf0',
                5: '#ffffff',
              }[statusIndex],
            }
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            const { syncTenantId } = this.$route.query
            let statusIndex = 5
            if (records.syncStatus == '0') {
              statusIndex = 1
            } else {
              if (records.tbStatus == '1') {
                if (records.cyStatus == '1') {
                  statusIndex = 2
                } else {
                  statusIndex = 0
                }
              } else if (records.tbStatus == '2') {
                statusIndex = 3
              } else if (records.tbStatus == '0') {
                statusIndex = 4
              }
            }
            return [
              {
                display: statusIndex !== 4 && statusIndex !== 5,
                name: '同步商品',
                type: 'pop',
                popTitle: () => (
                  <div>
                    {statusIndex == 2 ? (
                      <span>商品信息、店铺信息、运费模板存在配置差异，选用当前配置覆盖目标租户商品</span>
                    ) : (
                      <span>您确认同步商品吗？</span>
                    )}
                  </div>
                ),
                onClick: () => {
                  getAction(`/farmSyncNew/syncCheck?productIds=${records.id}&syncTenantId=${syncTenantId}`).then(
                    (res) => {
                      this.$message.success(res.data || '操作成功！')
                      this.initData()
                    }
                  )
                },
              },
              {
                display: statusIndex == 0 || statusIndex == 2,
                name: '取消同步',
                type: 'pop',
                popTitle: '确定取消同步?',
                onClick: () => {
                  putAction(`/farmSyncNew/channelSync?productId=${records.id}&syncTenantId=${syncTenantId}`).then(
                    (res) => {
                      this.$message.success(res.data || '操作成功！')
                      this.initData()
                    }
                  )
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      const { syncTenantId } = this.$route.query
      return [
        {
          name: '批量同步',
          isBatch: true,
          type: 'primary',
          onClick: (data) => {
            let arrId = data.map((e) => e.id).toString()
            this.spinning = true
            getAction(`/farmSyncNew/syncCheck?productIds=${arrId}&syncTenantId=${syncTenantId}`)
              .then((res) => {
                this.spinning = false
                this.$message.success(res.data || '操作成功！')
                this.initData()
              })
              .catch((err) => {
                this.spinning = false
              })
          },
        },
      ]
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          button={this.getButton()}
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          records={this.records}
        />
        <a-spin
          style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding-top:100px;"
          tip="Loading..."
          spinning={this.spinning}
        ></a-spin>
      </div>
    )
  },
}
</script>
